<template>
  <v-row no-gutters>
    <v-col cols="12">
      <SignInForm />
    </v-col>
    <v-col cols="12">
      <p class="text-center mt-8 my-0">
        <router-link
          class="error--text font-weight-black"
          :to="{ name: 'reset-password' }"
        >
          {{ $t("auth.forgot_password") }}?
        </router-link>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import SignInForm from "../components/SignInForm.vue";

export default {
  name: "SignIn",

  components: {
    SignInForm
  }
};
</script>
