<template>
  <form @submit.prevent="signIn">
    <v-sheet
      height="48"
      color="lightAccent"
      class="d-flex align-center px-4 rounded-md"
    >
      <v-icon left size="20" v-text="'$vuetify.icons.profile'" />
      <input
        ref="username"
        type="text"
        class="primary--text font-weight-bold _input"
        :placeholder="$t('auth.username')"
        required
        @input="credentials.username = $event"
      />
    </v-sheet>

    <v-sheet
      height="48"
      color="lightAccent"
      class="d-flex align-center mt-4 px-4 rounded-md"
    >
      <v-icon left size="20" v-text="'$vuetify.icons.lock'" />
      <input
        ref="password"
        type="password"
        autocomplete="on"
        class="primary--text font-weight-bold _input"
        :placeholder="$t('auth.password')"
        required
        @input="credentials.password = $event"
      />
    </v-sheet>

    <p class="error--text caption mb-0" v-html="apiError || '&nbsp;'" />

    <v-checkbox
      v-model="credentials.remember"
      class="font-weight-bold mt-0"
      :label="$t('auth.remember_me')"
      :true-value="1"
      :false-value="0"
    />

    <v-btn
      type="submit"
      color="primary"
      class="font-weight-black pa-6 rounded-md"
      block
      depressed
    >
      {{ $t("auth.sign_in") }}
    </v-btn>
  </form>
</template>

<script>
import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_ID_URL,
  headers: { "Content-type": "application/json" },
  withCredentials: true
});

const redirect = url => window.location.replace(url);

export default {
  name: "ChangeEmailForm",

  data: () => ({
    apiError: null,
    credentials: {
      remember: 1
    }
  }),

  methods: {
    async signIn() {
      this.apiError = null;

      this.credentials.username = this.$refs.username.value;
      this.credentials.password = this.$refs.password.value;

      const options = {
        return_url: this.$route.query.from ?? "",
        service: this.$route.query.service ?? "",
        grant_type: "password_otp"
      };

      api
        .post("auth", {
          ...this.credentials,
          ...options
        })
        .then(({ data }) => {
          if ("error" in data) this.apiError = this.$t("auth.invalid_data");
          if ("redirect_url" in data) redirect(data.redirect_url);
        })
        .finally(() => this.$store.commit("app/fetching", false));
    }
  }
};
</script>

<style scoped>
::placeholder {
  font-weight: normal;
  color: var(--v-info-base);
  opacity: 1;
}

._input {
  width: 100%;
  background: var(--v-transparent-base);
  outline: none;
}

._input:invalid {
  box-shadow: none;
}
</style>
